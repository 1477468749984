export const API_URL = 'https://inittechnology.co/web-backend'

export const All_Projects = [
    {
        id: 1,
        name: 'SOHAM VILLAS',
        timeframe: '2 Months',
        role: 'Full Cycle Website Recreation',
        description: 'Soham Villas, a premium company founded in 2018, is a collection of luxury property rentals in Goa.',
        technologies: ['ReactJs', 'NodeJs', 'Figma', 'CSS3', 'SVG'],
        features: ['Website', 'Admin Panel', 'Management Services', 'SEO', 'Content Writing'],
        images: [
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a9c05ac6-615b-41a4-7928-8153596a1d00/public', //init_soham_ss1.webp
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/958a939f-735d-4bfd-e6ed-8a9110d5f000/public', //init_soham_ss2.webp
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/80071016-a78a-42e5-343f-251277a4c600/public', //init_soham_ss3.webp
        ],
        logo: 'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b3ed5eef-b783-4d50-11e2-88fbaa3d0b00/public', //init_soham_box.webp
        link: 'https://sohamvillas.com/',
        description2: 'Our mission with Soham Villas was to create a visually appealing, and easy to use website for everyone across the globe, aiming to effectively showcase their beautiful properties. The entire team worked together, and the final outcome soars our vision and commitment to deliver a website that showcases what Soham Villas truly stands for.',
    },
    {
        id: 2,
        name: 'RPJ HOTEL',
        timeframe: '3 Months',
        role: 'Full Cycle Website Recreation alongside a Web-app',
        description: 'Nestled in the heart of lush Rajkot — RPJ has been at your service for the last 16 years as the centre of emerging hospitality business in Gujarat.',
        technologies: ['ReactJs', 'NodeJs', 'Figma', 'CSS3', 'SVG'],
        features: ['Website', 'Web-app', 'Management Services', 'SEO', 'Content Writing'],
        images: [
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/774e0559-03bc-496f-251f-7c5ddd7dc800/public', //init_rpj_ss1.webp
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a4564cb2-9fac-449d-7c36-350ddd410400/public', //init_rpj_ss2.webp
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0ae77245-fb51-49b9-d6ba-9819f15cea00/public', //init_rpj_ss3.webp
        ],
        logo: 'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/37adfae1-d8ba-49c1-9878-b22f21a14500/public', //init_rpj_box.webp
        link: 'https://rpjhotels.com/',
        description2: 'In our collaborate with RPJ Hotel, we created a simple, yet captivating website that majorly focuses on user experience. Our goal was to visually showcase the rooms, capturing the essence of it’s hospitality services and management.',
    },
    {
        id: 3,
        name: 'DRESSWALA',
        timeframe: '1 Month',
        role: 'Full Cycle Website Recreation',
        description: 'Founded on a passion for fashion and a commitment to quality, Dresswala is dedicated to providing you with the latest trends and timeless classics. Whether you are looking for the perfect outfit for a special occasion or everyday wear that makes you feel fabulous, they’ve got you covered.',
        technologies: ['Shopify', 'LiquidJs'],
        features: ['Website', 'Management Services', 'SEO', 'Content Writing'],
        images: [
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/993d0862-cdac-4ba4-e167-06b5ba1e1200/public', //init_dw_ss1.webp
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d62e996b-ca88-4d46-5425-e43493953f00/public', //init_dw_ss2.webp
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/cbcb7e36-715b-499e-72d8-06671ad47600/public', //init_dw_ss3.webp
        ],
        logo: 'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a3a441bd-ae11-4eb0-1c85-b56e30cd1c00/public', //init_dresswala_box.webp
        link: 'https://dresswalanx.com/',
        description2: 'The main goal for Dresswala was to optimize the space for text and images, and also make sure that the website runs effortlessly, delivering a smooth user experience. During the period of this collaboration we continue to update the images, and keep providing only the best for everyone.',
    },
    {
        id: 4,
        name: 'NEEL AYURVEDICS',
        timeframe: '3 Months',
        role: 'Full Cycle Web-App Creation',
        description: 'At Neel Ayurvedics, our mission is to make the healing power of Ayurveda accessible to all. We believe in empowering individuals to take charge of their well-being by providing natural products that align with the principles of Ayurveda. ',
        technologies: ['ReactJs', 'NodeJs', 'Figma', 'CSS3', 'SVG'],
        features: ['Web-app', 'Management Services'],
        images: [
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a3520b67-0509-481b-b7ae-94575d982c00/public', //init_neel_ss1.webp
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e06eecf-e5fa-4aef-37d8-98fb4bd04900/public', //init_neel_ss2.webp
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/87603076-00a2-4f6c-7e1d-83a34ff4ec00/public', //init_neel_ss3.webp
        ],
        logo: 'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/160bb1ed-8b8c-4c4e-4c65-33e62498ad00/public', //init_neel_box.webp
        link: 'https://shipment.inittechnology.co/',
        description2: 'We developed a comprehensive web-app for Neel Ayurvedics to enhance accessibility and improve user experience. We continue to refine the web-app with new versions, ensuring it remains intuitive and up-to-date with the latest offerings.',
    },
    {
        id: 5,
        name: 'OPEN STUDIOS',
        timeframe: '2 Months',
        role: 'Full Cycle Website Creation',
        description: 'Open Studios, your go-to creative hub in Rajkot! We are passionate about helping businesses shine with our award-winning advertising services. From stunning Photography & Cinematography to dynamic Social Media Management and cutting-edge 3D Visualisations, we bring your vision to life.',
        technologies: ['ReactJs', 'NodeJs', 'Figma', 'CSS3', 'Lottiefiles'],
        features: ['Website', 'Management Services', 'SEO', 'Content Writing'],
        images: [
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5538230c-3e86-4826-27ce-12bc48783100/public', //init_os_ss1.webp
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6e6bc102-ff28-4580-ed5b-b5543572ee00/public', //init_os_ss2.webp
            '../assets/CaseStudy/init_os_ss3.webp', //init_os_ss3.webp
        ],
        logo: 'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b053b87b-508e-41fa-314e-1c1c4f46c800/public', //init_openStudios_box.webp
        link: 'https://open-studios.co/',
        description2: 'We are building a dynamic website for Open Studios to showcase their  services. The site will provide a seamless experience, offering access to stunning Photography, Cinematography, Social Media Management, and beautiful 3D Visualisations. Our goal is to continuously update the platform to bring businesses visions to life while maintaining a sleek and user-friendly design.',
    },
    {
        id: 6,
        name: 'HOUSE OF SHAH',
        timeframe: '2 Months',
        role: 'Full Cycle E-Website Creation',
        description: 'Based in Rajkot, House of Shah is an up-and-coming name in the world of silver jewellery. With years of experience, they pride ourselves on creating high-quality, beautifully designed pieces that stand the test of time.',
        technologies: ['Wordpress', 'Figma',],
        features: ['E-commerce Website', 'Management Services',],
        images: [
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ca7f90ff-648d-43c9-8e42-b1c213234500/public', //init_houseOfShah_ss1.webp
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/45c32c64-794e-468a-2393-cbbc3a593300/public', //init_houseOfShah_ss2.webp
            'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5e6aa8db-b072-46e7-be28-aab4b22ff000/public', //init_houseOfShah_ss3.webp
        ],
        logo: 'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b346539b-4674-41c1-61f0-913677b46a00/public', //init_houseOfShah_box.webp
        link: 'https://houseofshah.in/',
        description2: 'With House of Shah, our truest challenge was that it came along with multiple plugin variations, and a very intricate set of functionality. However at Init we say “Nothing is impossible”, therefore this creation is one of its kind, with its elegant and minimal designing. We ensure complete client discrepancy and regular updates.',
    },
];
